.Page {
  position: relative;
  height: 100%;
  background: var(--white);
  padding: 0.5rem;
  margin: 1rem 1rem 5rem 1rem;
  transition: all linear 0.1s;

  &-wrapper {
    margin: 1rem 1rem 5rem 1rem;

    .Page {
      margin: 0;
    }
  }

  &--readonly {
    padding-bottom: 1.5rem;
  }

  &--innsendt {
    border: 1rem solid var(--activeblue);
  }

  &-title {
    font-size: 2.25rem !important;
    margin-bottom: 4rem;
    word-wrap: break-word;
    overflow-wrap: break-word;
  }

  &-header {
    float: right;
    margin-top: -3.5rem;
  }

  &-section {
    padding: 0 0.5rem;

    &--narrow {
      padding: 1rem 0.5rem;
    }

    &--empty {
      padding: 0 0.5rem;
    }
  }

  &-gray {
    background: var(--offwhite);
  }

  &-import label {
    background: var(--steelblue4);
  }
}

@media @large {
  .Page {
    flex: 1 1 auto;
    margin: 3rem 1rem 0 1rem;
    width: 100%;
    min-width: 0px; /* stylelint-disable-line */

    &--innsendt {
      margin: 3rem -1rem 0 -1rem;
    }

    &-wrapper {
      flex: 1 1 auto;
      margin: 3rem 1rem 0 1rem;
      width: 100%;
      min-width: 0px; /* stylelint-disable-line */

      .Page {
        margin: 0;
      }
    }
  }
}

@media @medium {
  .Page {
    &--innsendt {
      border: 2rem solid var(--activeblue);
    }

    &-section {
      padding: 0 2rem;

      &--narrow {
        padding: 1rem 2rem;
      }

      &--empty {
        padding: 0 2rem;
      }
    }
  }
}
