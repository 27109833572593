@import "@utdanningsdirektoratet/styles/base";
@import "components/page";
@import "components/header";
@import "components/fotnoter";
@import "components/grid";
@import "components/sporsmal";
@import "components/sidebar";
@import "components/seksjon";
@import "components/markerKommentar";
@import "components/vedlegg";
@import "components/svarAlternativ";
@import "components/obligatorisk";

* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

:root {
  --borderradius1: 0 !important;
}

body:not(.internal) {
  background: #3f3f3f !important;
}

body.v2 {
  background: #eaeaea !important;
}

.breadcrumbs {
  font-family: var(--font-family), Arial, sans-serif !important;
  color: #0174a1 !important;

  li a:not(.bokmerke) {
    color: #0174a1 !important;
  }
}
