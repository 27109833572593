@import (less) "~animate.css/animate.css";

.TutorialOverlay {
  position: fixed;
  background: rgb(17 17 17 / 0.9);
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  color: var(--white);
  z-index: 200;

  &-wrapper {
    display: flex;
    justify-content: center;
    position: fixed;
    top: 50%;
    width: 100%;
    transform: translate(0, -50%);
    text-align: center;
  }

  &-box {
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    transform: translateX(-50%);
    margin-left: 50%;
    position: relative;
    width: 13rem;
    height: auto;
    text-align: center;
    vertical-align: bottom;
    transition: all 0.2s ease-in-out;

    svg {
      margin-bottom: 1rem;
    }

    &--icon {
      display: none;
    }
  }

  &-arrow {
    path,
    g {
      fill: var(--white);
    }

    transform: translate(0, -50%);
    top: 50%;
  }

  &-badge {
    .animate__animated;

    background: #ea004f;
    border-radius: 50%;
    color: var(--white);
    height: 1.875rem;
    width: 1.875rem;
    line-height: 1.875rem;
    position: absolute;
    top: 1rem;
    right: 2.5rem;
  }

  &-box:hover &-badge {
    animation-name: bounce;
  }

  &-hand {
    top: 3rem;
    height: 50%;
    position: absolute;
    right: 2rem;
    width: 50%;
  }

  &-footer {
    position: fixed;
    bottom: 1rem;
    width: 100%;
    text-align: center;

    a {
      cursor: pointer;
      color: var(--white);
      text-decoration: underline;

      &:hover,
      &:active,
      &:focus {
        color: var(--white);
      }
    }
  }

  &-button {
    border-color: var(--white);
    color: var(--white);

    &:hover {
      color: var(--darkgrey);
    }

    &::after {
      background: var(--white);
    }
  }

  &-popin-buttons {
    display: flex;
    justify-content: flex-end;
  }
}

@media @medium {
  .TutorialOverlay {
    &-box {
      transform: none;
      margin-left: 0;
      display: inline-block;

      &--icon {
        width: auto;
        display: inline-block;
      }
    }

    &-footer {
      bottom: 10%;
    }
  }
}

// Hack for akkurat mellomrommet mellom 980px og 1040px
@media only screen and (width >= 980px) and (width <= 1040px) {
  .TutorialOverlay {
    &-box {
      &--icon {
        width: 6.5rem !important;
      }
    }
  }
}

@media @large {
  .TutorialOverlay {
    &-box {
      &--icon {
        width: 13rem;
      }
    }
  }
}
